* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: sans-serif;
}

.logbox {
    background-color: #eee;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    padding: 40px 20px;
    border-radius: 2px;
}

.logbox h3 {
    text-align: center;
    color: #8c8c8c;
    /* padding: 30px 0px; */
}

.logbg {
    background-color: #010c1c;
    min-height: 100vh;
    position: relative;

}

.cus-formcontrol:focus {
    box-shadow: none;
    border-color: rgb(175, 175, 175);
}

.cus-formcontrol {
    color: #000 !important;
    /* padding: 10px !important; */
}

.linkbox a {
    color: #595959;
}

.form-btn {
    color: #fff;
    background-color: #0eb7d1;
}

.form-btn:hover {
    color: #fff;
    background-color: #0eb7d1ef;
}

.forgotcus {
    padding: 7px 10px;
    border-color: gray;
    width: 100%;
    outline: none;
    margin: 2px 0px;
}

.forgot-formcontrol {
    margin: 10px 0px;
}

.forgotcus:focus {
    border-color: gray;
    outline: none;
}

.forgotbtn {
    padding: 7px 20px;
    /* width: 100%; */
    border: 0;
    outline: none;
    /* / */

}


/* media qaury/ */

@media screen and (max-width:600px) {
    .logbox {
        background-color: #eee;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        padding: 40px 20px;
        border-radius: 2px;
    }
}