.candel-card {
    background-color: rgb(0, 0, 0);
    width: 600px;
    margin: auto;
    margin-top: 20px;
    padding: 10px;
}

@media screen and (max-width: 600px) {
    .candel-card {
        background-color: rgb(0, 0, 0);
        width: 100%;
        margin: auto;
        margin-top: 20px;
        padding: 10px;
    }
}